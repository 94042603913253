import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTheaterMasks, faUserAstronaut, faSearchLocation, faUmbrella, faRunning, faPen, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./App.css";






const iconMapping = {
  Genre: <FontAwesomeIcon icon={faTheaterMasks} />,
  Character: <FontAwesomeIcon icon={faUserAstronaut} />,
  Setting: <FontAwesomeIcon icon={faSearchLocation} />,
  Object: <FontAwesomeIcon icon={faUmbrella} />,
  Action: <FontAwesomeIcon icon={faRunning} />
};

const keyInfo = {
  Genre: "Deciding on what genre to write could be a deal changer. Comedy or Western, or both, can help you decide on the rules of the story from the get go.",
  Character: "A lot can happen to a character at their day job. Imagine the amount of things a window cleaner might see? Or what could a farmer unearth from the ground one day?",
  Setting: "Confining your characters to one or two settings can spark great atmospheric confinements. Does your story take place in a barbershop or in a newsroom? How would those stories differ?",
  Object: "Imagine your character finding a key or an old vintage comic book? An object could set your character on a quest like no other. Just imagine The Lord of the Rings without the one ring!",
  Action: "All our characters need an action. Whether it be running, belching, screaming or even apologising. These actions can help spark a plot to your story."
};

const App = () => {
  const [suggestions, setSuggestions] = useState({
    Genre: "",
    Character: "",
    Setting: "",
    Object: "",
    Action: ""
  });
  const [generateButtonClicked, setGenerateButtonClicked] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [suggestionsGenerated, setSuggestionsGenerated] = useState(false);
  const [showWriterContainer, setShowWriterContainer] = useState(false);






  const generateSuggestions = async () => {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const prompt = "Generate unique and unrelated story elements: Genre, Character, Setting, Object, and Action. Create a combination that sparks creativity and surprises. Think of unusual and unexpected connections between these elements. Action should be one word. Surprise me with your creativity!";
    const apiUrl = "https://api.openai.com/v1/engines/text-davinci-003/completions";
    const maxTokens = 150;





  try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          prompt: prompt,
          max_tokens: maxTokens,
          n: 5, // Number of suggestions to generate (1 suggestion for each key)
        }),
      });

      const data = await response.json();
      const choices = data.choices[0].text.trim().split('\n');
      setSuggestions({
        Genre: choices[0].replace('Genre: ', ''),
        Character: choices[1].replace('Character: ', ''),
        Setting: choices[2].replace('Setting: ', ''),
        Object: choices[3].replace('Object: ', ''),
        Action: choices[4].replace('Action: ', '')
      });
      setGenerateButtonClicked(true); 
      setSuggestionsGenerated(true); 


    } catch (error) {
      console.error("Error generating suggestions:", error);
    }
  };


 const openInfoTab = (key) => {
    setSelectedKey(key);
  };

  const closeInfoTab = () => {
    setSelectedKey(null);
  };

    useEffect(() => {
    if (suggestionsGenerated) {
      const timer = setTimeout(() => {
        setShowWriterContainer(true);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [suggestionsGenerated]);

  return (
    <div className="container">

  

      <h1>Story Keys</h1>



  <h3>generate five essential story elements in just one click  </h3>



{!suggestionsGenerated && (
  <button onClick={generateSuggestions}><strong>GENERATE STORY KEYS</strong></button>
)}
<div className={generateButtonClicked ? "result-container" : "result-container hidden"}>
      <div className="keys">
        {Object.keys(suggestions).map((key) => (
          <div key={key} className="key">
            <p className="key-title"><strong>{iconMapping[key]} {key}</strong> </p>
            <p><i>{suggestions[key]}</i></p>
            <span className="info-tab" onClick={() => openInfoTab(key)}><FontAwesomeIcon icon={faInfoCircle} /></span>
          </div>
        ))}
      </div>


      {selectedKey && (
        <div className="info-tab-content">
          <span className="close-btn" onClick={closeInfoTab}>&times;</span>
          <h2>{selectedKey}</h2>
          <p>{keyInfo[selectedKey]}</p>
        </div>
      )}
      <div className={`writer-container ${showWriterContainer ? 'show' : ''}`}>
       
      <p className="writing-text">can you write a story incorporating these five suggestions?</p>
      ......<FontAwesomeIcon icon={faPen} className="pen-icon" />
      <p className="writing-text">hurry, before someone steals your idea!</p>
     

      {generateButtonClicked && suggestionsGenerated && (
  <button onClick={generateSuggestions}><strong>GENERATE AGAIN</strong></button>
)}
    </div>

      </div>



   <div className="footer">
      <p className="copyright">
        Copyright &copy; {new Date().getFullYear()}. All Rights Reserved.
        Created by <a href="https://www.matthewlynewatt.com/" target="_blank" rel="noopener noreferrer">Matthew Lyne-Watt</a>
      </p>
    </div>





    </div>
  );
};

export default App;